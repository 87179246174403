$(document).ready(function () {
  if ($("#accept-options-buttons").length > 0) {
    console.log("Accept buttons present");
    $(document).on('click', '#lbl_same_person', function (evt) {
      $('#same_person_form').removeClass("noShow");
      $('#different_person_form').addClass("noShow");
    });

    $(document).on('click', '#lbl_different_person', function (evt) {
      $('#same_person_form').addClass("noShow");
      $('#different_person_form').removeClass("noShow");
    });
  }
});