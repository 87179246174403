import * as WebAuthnJSON from "@github/webauthn-json"
import * as Message from 'message'

$(document).ready(function () {
  $(document).on('ajax:success', '#webauthn-create-form', function(evt) {
    var [data, status, xhr] = evt.detail;
    console.log(data);
    var credentialOptions = data;
    console.log(credentialOptions["user"]);

    if (credentialOptions["user"]) {
      var credential_nickname = evt.target.querySelector("input[name='authorized_key[name]']").value;
      var callback_url = `/users/tfa/webauthn/callback?credential_nickname=${credential_nickname}`

      create_key(encodeURI(callback_url), credentialOptions);
    }
  });  

  $(document).on('click', "#webauthn-retry", function(evt) {
    authenticate();
  });

  authenticate();
});


function create_key(callbackUrl, credentialOptions) {
  WebAuthnJSON.create({ "publicKey": credentialOptions }).then(function(credential) {
    callback(callbackUrl, credential, "/users/tfa/settings");
  }).catch(function(error) {
    console.log(error);
    Message.message("<i class=\"fas fa-exclamation-circle\"></i> Authentification à deux facteurs", "Une erreur est survenue lors de l'ajout de votre clé d'authentification. Veuillez réessayer.");
  });

  console.log("Creating new public key credential...");
}

function callback(url, body, success_url = nil) {
  fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-CSRF-Token": getCSRFToken()
    },
    credentials: 'same-origin'
  }).then(function(response) {
    if (response.ok) {
      window.location.replace(success_url)
    } else if (response.status < 500) {
      console.log(response.text);
      Message.message("<i class=\"fas fa-exclamation-circle\"></i> Authentification à deux facteurs", "L'opération n'a pas pu être complétée.");
    } else {
      console.log(response.status);
      console.log(response.text);
      Message.message("<i class=\"fas fa-exclamation-circle\"></i> Authentification à deux facteurs", "Une erreur est survenue. Veuillez réessayer.");
    }
  });
}

function getCSRFToken() {
  var CSRFSelector = document.querySelector('meta[name="csrf-token"]')
  if (CSRFSelector) {
    return CSRFSelector.getAttribute("content")
  } else {
    return null
  }
}

function authenticate() {
  var url = "/users/sessions/webauthn";

  if ($("#webauthn-prompt").length) {
    $.ajax(url, {
      type: 'POST',
      dataType: 'json',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": getCSRFToken()
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}");
      },
      success: function (data, textStatus, jqXHR) {
        create_webauthn_prompt(data, textStatus, jqXHR);
      }
    });
  }
}

function create_webauthn_prompt(data, status, xhr) {
    console.log(data);
    var credentialOptions = data;
    get_credential(credentialOptions);  
}

function get_credential(credentialOptions) {
  WebAuthnJSON.get({ "publicKey": credentialOptions }).then(function(credential) {
    callback("/users/sessions/webauthn_sign_in?tfa_method=webauthn", credential, "/");
  }).catch(function(error) {
    console.log(error);
    Message.message("<i class=\"fas fa-exclamation-circle\"></i> Authentification à deux facteurs", "L'opération n'a pas pu être complétée. Veuillez réessayer ou choisir une autre méthode d'authentification.");
  });
}