import 'jquery-dirty-forms'
import 'jquery.dirtyforms.dialogs.bootstrap'
import '@client-side-validations/client-side-validations'
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4'

$(document).ready(function () {
  $(document).on('click', '.insert-fields-button', function(e) {
    add_fields(e.target);
  });   

  $(document).on('click', '.delete-field-button', function(e) {
    remove_fields(e.target);
  });

  $(document).on('click', '.restore-deleted-item', function(e) {
    restore_fields(e.target);
  });

  $('#select_all').on('change', function(e) {
    select_all(e.target);
  });

  $('.select-all-button').on('click', function(e) {
    select_all_button(e.target, true);
  });

  $('.deselect-all-button').on('click', function(e) {
    select_all_button(e.target, false);
  });

  $(document).on('dirty.dirtyforms', function(event) { 
    $(".dirty").not("form").add(".alwaysDirty").each( function(index) {
      var parent = $(this).closest(".form-group");

      if (parent.length > 0) 
        $(parent).addClass("alert-warning");

      $(this).addClass("alert-warning");
      
    });

    set_reflected_dirty_flag();
    reset_clean_fields();
  });    

  $(document).on('clean.dirtyforms', function(event) { 
    reset_clean_fields();
    set_reflected_dirty_flag();
  });    

  $(document).on('dirtyfield.dirtyforms', function(event) { 
    reset_clean_fields();
    update_tab_status();
    set_reflected_dirty_flag();
  });    

  $(document).on('rescan.dirtyforms', function(event) { 
    reset_clean_fields();
    update_tab_status();
    set_reflected_dirty_flag();
  });    


  $(document).on('bind.dirtyforms', function (ev, events) {
    var originalBind = events.onAnchorClick;
    var sameWindow = ["", "_self", "_parent", "_top"];

    events.onAnchorClick = function (ev) {
      if ($(ev.target).is("a")) {
        if (sameWindow.indexOf(ev.target.target) >= 0)
          originalBind(ev);
      }
    };
  });

  initialize_dirty_forms();

  $(document).on('hidden.bs.modal', '.modal', function (event) {
      $(this).removeClass( 'fv-modal-stack' );
      $('body').data( 'fv_open_modals', $('body').data( 'fv_open_modals' ) - 1 );
  });

  $(document).on('shown.bs.modal', '.modal', function (event) {
      // keep track of the number of open modals
      if ( typeof( $(document).find('body').data( 'fv_open_modals' ) ) == 'undefined' ) {
          $(document).find('body').data( 'fv_open_modals', 0 );
      }

      // if the z-index of this modal has been set, ignore.
      if ($(this).hasClass('fv-modal-stack')) {
          return;
      }

      $(this).addClass('fv-modal-stack');
      $('body').data('fv_open_modals', $('body').data('fv_open_modals' ) + 1 );

      var index = 1040 + (10 * $('body').data('fv_open_modals' ));
      $(this).css('z-index', index);
      $('.modal-backdrop').not('.fv-modal-stack').css('z-index', index - 1);
      $('.modal-backdrop').not('fv-modal-stack').addClass('fv-modal-stack'); 
  });        

  $('.modal').on("hidden.bs.modal", function (e) { 
      if ($('.modal:visible').length) { 
          $('body').addClass('modal-open');
      }
  });  

  $(".image-upload").on('change', function(){
      preview_image(this);
  });    

  $('.image-picker').on('click', function () {
    $(this).closest('.file-input').find('.image-upload').click();
  });

  $('.tag-tooltip').tooltip();
});

export function initialize_dirty_forms() {
  var formSelector = $('form').not(".noDirtyForms");
  formSelector.dirtyForms({
    dialog: {
      dialogID: 'dirty-form-dialog', 
      titleID: 'custom-title', 
      messageClass: 'custom-message', 
      proceedButtonClass: 'custom-proceed', 
      stayButtonClass: 'custom-stay',
      replaceText: false
    },
    debug: false,
    fieldSelector: "input:not([type='button'],[type='image'],[type='submit']," +
                "[type='reset'],[type='search']),select,textarea",
    helpers: 
      [  
          {
              isDirty: function ($node, index) {
                    console.log("Helper triggered " + $node.hasClass('alwaysDirty') + " for node " + $node.prop('nodeName'));
                    return ($node.hasClass('alwaysDirty') || ($node.find(".alwaysDirty").length > 0));
                },

              rescan: function ($form, index) {
                $form.find(".alwaysDirty").each( function (index) {
                  var $field = $(this);
                  $.DirtyForms.dirtylog('Triggering dirtyfield.dirtyforms for ' + $field.attr('id') );
                  $field.trigger('dirtyfield.dirtyforms');                    

                  $.DirtyForms.dirtylog('Setting dirty status to ' + true + ' on form ' + $form.attr('id'));
                  $form.toggleClass($.DirtyForms.dirtyClass, true);
                  $form.trigger('dirty.dirtyforms');                  
                })
              }
          }
      ]      
  });      

  $(document).bind('trix-change', function (event) {
    formSelector.dirtyForms('rescan');
  });

  $(document).on('change', "select[data-sublist='true']", function (evt) {
    update_sublist(this);
  });
}

function reset_clean_fields() {
  $(".form-group").not("form").each( function (index) {
    var dirty = $(this).find(".dirty").add(".alwaysDirty");

    if (dirty.length === 0)
      $(this).removeClass("alert-warning");
  });

  $(".form-control").not(".dirty").removeClass("alert-warning");
}  

function update_tab_status() {
  $("div.tab-pane").each( function (index) {
    var tab = $('a[href="#' + $(this).attr('id') + '"]');
    var dirty = $(this).find(".dirty").add($(this).find(".alwaysDirty"));
    var edited = $(tab).find(".edited");

    if (dirty.length > 0) {
      if (edited.length == 0)
        $(tab).prepend('<span class="edited fas fa-asterisk"></span>');
    }
    else {
      if (edited.length > 0)
        $(edited).remove();
    }
  });
}

function set_reflected_dirty_flag() {
  $(".dirty-flag").each( function (index) {
    var container = $(this).data('dirty-group');

    if (container.length > 0)
      $(this).toggleClass("alert-warning", ($(container).find(".dirty").length > 0))
    else
      $(this).toggleClass("alert-warning", false);
  });  
}

export function add_fields(elem) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + elem.dataset.association, "g")
  $("#"+elem.dataset.insertBefore).before(elem.dataset.fields.replace(regexp, new_id));  
  
  if (elem.dataset.hooks.length > 0)
    eval(elem.dataset.hooks);

  $('input[type="checkbox"][data-toggle="toggle"]').bootstrapToggle();
}

export function remove_fields(elem) {
  var prev = $(elem).prev("input[type=hidden]");
  prev.val("1");

  var prev_id = prev.attr('name');
  var id = prev_id.substring(0, prev_id.lastIndexOf("[")) + "[id]";

  var id_elem = $('[name="'+id+'"]');  

  remove_fields_line(elem, id_elem);
  $('form').not(".noDirtyForms").dirtyForms('rescan');
}

export function remove_fields_line(elem, id) {
  var remove_elem = $(elem).closest(".fields");
  var group_remove = $(remove_elem).data("group-remove");
  var shadow_remove = $(elem).data("shadow-remove")

  if (!(typeof group_remove === 'undefined'))
    remove_elem = $("." + group_remove);

  if (id.length == 0)
    $(remove_elem).remove()
  else if (shadow_remove) {
    $(remove_elem).addClass("table-dark");
    $(remove_elem).find(".hide-removed").hide();
    $(remove_elem).find(".show-removed").show();
    $(remove_elem).find(".form-control:visible").prop("disabled", true);
  } 
  else
    $(remove_elem).hide();
}

export function restore_fields(elem) {  
  var prev = $(elem).parent().find("input[type=hidden]");
  prev.val("false");

  activate_fields_line(elem);
  $('form').not("noDirtyForms").dirtyForms('rescan');
}

export function activate_fields_line(elem) {
  var remove_elem = $(elem).closest(".fields");
  var group_remove = $(remove_elem).data("group-remove");

  if (!(typeof group_remove === 'undefined'))
    remove_elem = $("." + group_remove);

  $(remove_elem).removeClass("table-dark");
  $(remove_elem).find(".hide-removed").show();
  $(remove_elem).find(".show-removed").hide();
  $(remove_elem).find(".form-control:visible").not('[data-disable=true]').prop("disabled", false);
}

function preview_image(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();            
    var preview = $(input).closest(".file-input").find(".image-picker");

    if (preview.length > 0) {
      reader.onload = function (e) {
        $(preview).attr('src', e.target.result);
      }

      reader.readAsDataURL(input.files[0]);
    }
  }
}

export function formatMoney(val) {
  var res = val.toFixed(2) + " $";
  return res;
}

function select_all(elem) {
  var selector = $(elem).data("selector");
  var selected = $(elem).is(':checked');

  do_selection(selector, selected);
}

function select_all_button(elem, selected) {
  var selector = $(elem).data("selector");

  do_selection(selector, selected);
}

function do_selection(selector, selected) {
  $(selector).each(function() {
    $(this).prop('checked', selected);
  });
}

function update_sublist(source) {
  var url = $(source).data("endpoint");
  var params = JSON.parse(JSON.stringify($(source).data("endpoint-params")));
  var selected = $(source).find(":selected").val();

  Object.keys(params).forEach( function (key) {
    if (params[key] == "#selected#")
      params[key] = selected;
  });

  $.ajax(url, {
    type: 'GET',
    dataType: 'script',
    data: params,
    error: function (jqXHR, textStatus, errorThrown) {
      console.log("AJAX error XHR: " + jqXHR);
      console.log("AJAX Error: " + textStatus);
      console.log("AJAX Error thrown: " + errorThrown);
    }
  });
}
